import {  useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import FormControllerService, {
  FuneralType,
  Location,
  FormData,
  Cost
} from "../services/form-controller.service";
import { InputMask } from 'primereact/inputmask';

export interface formSectionOneProps {
  formControllerService: FormControllerService; // this service will manage all values from the inputs
}
const FormSectionOne = (props: formSectionOneProps) => {
  // state variables
  const [_selectedLocation, setLocation] = useState<string>();
  const [_toggleNew, setToggleNew] = useState<boolean>(false);
  const [_selectedFuneralDate, setFuneralDate] = useState<Date>();
  const [_selectedFuneralStartTime, setFuneralStartTime] = useState<any>();
  const [_selectedGraveTime, _setSelectedGraveTime] = useState<any>();
  const [_selectedFuneralType, _setSelectedFuneralType] = useState<any>();
  const [_selectedNewOrExisting, _setNewOrExisting] = useState<any>();
  const [_selectedNewYears, _setNewYears] = useState<number>();
  const [_selectedExtendingYears, _setExtendingYears] = useState<number>();
  const [_selectedExtendExisting, setExtendExisting] = useState<string>();
  const [_currentFormData, setCurrentFormData] = useState<FormData>();
  const [_costs, setCosts] = useState<Cost[]>([]);
  const [yearsDropdown, setYearsDropdown] = useState<any>();
  const [focusedFields, setFocusedFields] = useState(new Set());
  const [newExistingDropdown, setNewExistingDropdown] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFocus = (field: any) => {
    setFocusedFields((prev) => new Set(prev).add(field));
  };

  const jaaNeeDropdown = [
    { label: "Ja", value: "Ja" },
    { label: "Nee", value: "Nee" },
  ];

  const locationDropdown =
    props.formControllerService._controlData.LOCATIONS.map(
      (eachOne: Location) => {
        return {
          value: eachOne.location,
          label: eachOne.location,
        };
      }
    );

  let funeralTypeDropdown =
    props.formControllerService._controlData.FUNERALTYPES
    .sort((a: FuneralType, b: FuneralType) => a.order - b.order) 
    .map(
      (eachType: FuneralType) => {
        return {
          value: eachType.burial_type,
          label: eachType.burial_type,
        };
      }
    );

  const [_funeralTypesDropdown, setFuneralTypesDropdown] =
    useState<any>(funeralTypeDropdown);

  const resetControls = () => {
    let soort = document.getElementById("soort-control");
    if (soort) {
      soort.style.display = "flex";
    }

    let years__ = document.getElementById("new-years");
    if (years__) {
      years__.style.display = "none";
    }

    let extend_ = document.getElementById("existing-extend");

    if (extend_) {
      extend_.style.display = "none";
    }

    let nex = document.getElementById("new-exist");
    if (nex) {
      nex.style.display = "none";
    }

    let ey = document.getElementById("extend-years");
    if (ey) {
      ey.style.display = "none";
    }
  };
  const showControls = () => {
    if (_currentFormData?.nieuw_of_bestaand_graf == "Nieuw") {
      const _soortControls = document.getElementById("soort-control");
      if (_soortControls) {
        _soortControls.style.display = "flex";
      }
      const _newExist = document.getElementById("new-exist");
      if (_newExist) {
        _newExist.style.display = "flex";
      }
      const _newYears = document.getElementById("new-years");
      if (_newYears) {
        _newYears.style.display = "flex";
      }
      const years =
        props.formControllerService.filterYearsByBurialTypeAndNewExisting();
      setYearsDropdown(years);
    } else if (_currentFormData?.nieuw_of_bestaand_graf == "Bestaand") {
      const _soortControls = document.getElementById("soort-control");
      if (_soortControls) {
        _soortControls.style.display = "flex";
      }
      const _newExist = document.getElementById("new-exist");
      if (_newExist) {
        _newExist.style.display = "flex";
      }
      if (_currentFormData?.bestaand_graf_verlenging == "Ja") {
        const _extend = document.getElementById("existing-extend");
        if (_extend) {
          _extend.style.display = "flex";
        }

        const _extendYears = document.getElementById("extend-years");
        if (_extendYears) {
          _extendYears.style.display = "flex";
        }

        const years =
          props.formControllerService.filterYearsByBurialTypeAndNewExisting();
        setYearsDropdown(years);

        // setNewExistingDropdown([{ label: "Bestaand", value: "Bestaand" }])
      }
    } else if (_currentFormData?.soort_begrafenis !== "") {
      const _soortControls = document.getElementById("soort-control");
      if (_soortControls) {
        _soortControls.style.display = "flex";
      }
    }
  };

  const _globalFieldChangeHandler = (e: any) => {
    // get value and id of changing element
    const _id = e.target.id || e.id;
    const _value = e.target.value || e.value;

    // set the value in the service
    props.formControllerService.setFormData(_id as any, _value);

    // set the value in the state and any validations
    switch (_id) {
      case "uitvaart_nummer":
        setCurrentFormData({
          ...(_currentFormData as any),
          uitvaart_nummer: _value,
        });
        break;
      case "ondernemer_bedrijfsnaam":
        setCurrentFormData({
          ...(_currentFormData as any),
          ondernemer_bedrijfsnaam: _value,
        });
        break;
      case "kvk_nummer":
        setCurrentFormData({
          ...(_currentFormData as any),
          kvk_nummer: _value,
        });
        break;
      case "ondernemer_email":
        setCurrentFormData({
          ...(_currentFormData as any),
          ondernemer_email: _value,
        });

        break;
      case "uitvaartleider_naam":
        setCurrentFormData({
          ...(_currentFormData as any),
          uitvaartleider_naam: _value,
        });

        break;
      case "uitvaartleider_telefoon":
        setCurrentFormData({
          ...(_currentFormData as any),
          uitvaartleider_telefoon: _value,
        });

        break;
      case "ondernemer_registratienummer":
        setCurrentFormData({
          ...(_currentFormData as any),
          ondernemer_registratienummer: _value,
        });

        break;
      case "achternaam_overledene":
        setCurrentFormData({
          ...(_currentFormData as any),
          achternaam_overledene: _value,
        });

        break;
      case "locatie":
        setCurrentFormData({
          ...(_currentFormData as any),
          locatie: _value,
          soort_begrafenis: "",
          nieuw_of_bestaand_graf: "",
          nieuw_graf_aantal_jaren: "",
          bestaand_graf_verlenging: "",
          bestaand_graf_verlenging_aantal_jaren: "",
        });
        const filteredTypes = props.formControllerService.filteredFuneralTypes(
          _value as string
        );
        let _funeralTypeDropdown = filteredTypes.map(
          (eachType: FuneralType) => {
            return {
              value: eachType.burial_type,
              label: eachType.burial_type,
            };
          }
        );
        setFuneralTypesDropdown(_funeralTypeDropdown);

        resetControls();

        break;
      case "datum_uitvaart":
        let checkHoliday = props.formControllerService.checkForHoliday(_value);
        const dateHolder = document.getElementById("datum_uitvaart");
        if (checkHoliday) {
          props.formControllerService._holidaySurCharge = true;
          if (dateHolder && (checkHoliday)) {
            dateHolder.style.border = "2px solid red";
          }
        } else {
          props.formControllerService._holidaySurCharge = false;
          if (dateHolder) {
            // console.log('into')
            dateHolder.style.border = "none";
          }
        }

        setCurrentFormData({
          ...(_currentFormData as any),
          datum_uitvaart: _value,
        });

        break;
      case "aanvangstijd_uitvaart":
        setCurrentFormData({
          ...(_currentFormData as any),
          aanvangstijd_uitvaart: _value,
        });

        break;
      case "aanvangstijd_groeve":
        setCurrentFormData({
          ...(_currentFormData as any),
          aanvangstijd_groeve: _value,
        });

        break;
      case "soort_begrafenis":
        setCurrentFormData({
          ...(_currentFormData as any),
          soort_begrafenis: _value,
          nieuw_of_bestaand_graf: "",
          nieuw_graf_aantal_jaren: "",
          bestaand_graf_verlenging: "",
          bestaand_graf_verlenging_aantal_jaren: "",
        });

        resetControls();

        props.formControllerService.setFuneralType(_value);
        const _check = props.formControllerService._controlData.COSTS.filter(
          (eachCost: Cost) => {
            return eachCost.burial_type == _value;
          }
        );

        if (_check.length > 0) {
          let newExistingDropdown = [];
          let _newCheck = _check.filter((eachOne: Cost) => {
            return eachOne.new_exist == "Nieuw";
          });
          let _existingCheck = _check.filter((eachOne: Cost) => {
            return eachOne.new_exist == "Bestaand";
          });

          if (_newCheck.length > 0) {
            newExistingDropdown.push({ label: "Nieuw", value: "Nieuw" });
          }
          if (_existingCheck.length > 0) {
            newExistingDropdown.push({ label: "Bestaand", value: "Bestaand" });
          }
          let new_ = document.getElementById("new-exist");
          if (new_ && _check.length > 0) {
            new_.style.display = "flex";
          }

          setNewExistingDropdown(newExistingDropdown);
        } else {
          setCurrentFormData({
            ...(_currentFormData as any),
            nieuw_of_bestaand_graf: "",
            nieuw_graf_aantal_jaren: "",
            bestaand_graf_verlenging: "",
            bestaand_graf_verlenging_aantal_jaren: "",
            soort_begrafenis: _value,
          });
          console.log("into the no costs leg");
          let years_ = document.getElementById("new-years");
          if (years_) {
            years_.style.display = "none";
          }
          props.formControllerService.setFormData("nieuw_of_bestaand_graf", "");
          props.formControllerService.setFormData(
            "nieuw_graf_aantal_jaren",
            ""
          );
          props.formControllerService.setFormData(
            "bestaand_graf_verlenging",
            ""
          );
          props.formControllerService.setFormData(
            "bestaand_graf_verlenging_aantal_jaren",
            ""
          );

          let new_ = document.getElementById("new-exist");
          if (new_) {
            new_.style.display = "none";
          }

          let ey = document.getElementById("extend-years");
          if (ey) {
            ey.style.display = "none";
          }

          let extend_ = document.getElementById("existing-extend");

          if (extend_) {
            extend_.style.display = "none";
          }
        }

        break;
      case "nieuw_of_bestaand_graf":
        const years =
          props.formControllerService.filterYearsByBurialTypeAndNewExisting();
        setYearsDropdown(years);
        if (_value == "Nieuw") {
          let years_ = document.getElementById("new-years");
          let extend = document.getElementById("existing-extend");

          let ey = document.getElementById("extend-years");
          if (ey) {
            ey.style.display = "none";
          }
          if (years_) {
            years_.style.display = "flex";
          }

          if (extend) {
            extend.style.display = "none";
          }
        } else {
          let extend = document.getElementById("existing-extend");
          let years = document.getElementById("new-years");

          if (years) {
            years.style.display = "none";
          }

          if (extend) {
            extend.style.display = "flex";
          }
        }

        setCurrentFormData({
          ...(_currentFormData as any),
          nieuw_of_bestaand_graf: _value,
        });

        break;
      case "nieuw_graf_aantal_jaren":
        setCurrentFormData({
          ...(_currentFormData as any),
          nieuw_graf_aantal_jaren: _value,
        });

        break;
      case "bestaand_graf_verlenging":
        setCurrentFormData({
          ...(_currentFormData as any),
          bestaand_graf_verlenging: _value,
          bestaand_graf_verlenging_aantal_jaren: "",
        });

        if (_value === "Ja") {
          let extend = document.getElementById("extend-years");
          if (extend) {
            extend.style.display = "flex";
          }
        } else {
          let extend = document.getElementById("extend-years");
          if (extend) {
            extend.style.display = "none";
          }
        }

        break;
      case "bestaand_graf_verlenging_aantal_jaren":
        setCurrentFormData({
          ...(_currentFormData as any),
          bestaand_graf_verlenging_aantal_jaren: _value,
        });

        break;
      default:
        break;
    }
  };

  // a function to convert a string mm-dd-yyyy to a date object
  function parseDateFromEUString(input: string) {
    const parts = input.split("-");
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  }
  const _unpackPrevSession = () => {
    setNewExistingDropdown([
      { value: "Nieuw", label: "Nieuw" },
      { value: "Bestaand", label: "Bestaand" },
    ]);

    if (props.formControllerService.hasPrevSession()) {


      const _nextNumber =
        props.formControllerService._controlData.NEXTNUMBERS[0].currentNumber;

      const _uNumber =
        props.formControllerService._formData.uitvaart_nummer == ""
          ? _nextNumber
          : props.formControllerService._formData.uitvaart_nummer;

      if (
        props.formControllerService._formData.datum_uitvaart !== ''
      ) {
        handleFocus("datum_uitvaart");
      
      }

      if (
        props.formControllerService._formData.aanvangstijd_uitvaart !== ''
      ) {
        handleFocus("aanvangstijd_uitvaart");
      
      }

      if (
        props.formControllerService._formData.aanvangstijd_groeve !== ''
      ) {
        handleFocus("aanvangstijd_groeve");
      
      }


     
        

      props.formControllerService.setFormData("uitvaart_nummer", _uNumber);
      props.formControllerService.setFormData(
        "soort_begrafenis",
        props.formControllerService._formData.soort_begrafenis
      );

      setCurrentFormData({
        ...props.formControllerService._formData,

        uitvaart_nummer: _uNumber,
        nieuw_of_bestaand_graf:
          props.formControllerService._formData.nieuw_of_bestaand_graf,
      });
    }
  };

  useEffect(() => {
    if (props.formControllerService.hasPrevSession()) {
      showControls();
    }
  }, [_currentFormData]);

  useEffect(() => {
    _unpackPrevSession();
    if (
      (props.formControllerService._READ_ONLY &&
        props.formControllerService._CURRENT_ROLE !== "ADMIN") ||
      props.formControllerService.getSession()?.status == "COMPLETED_APPROVED"
    ) {
      setIsDisabled(true);
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        padding: 15,
        display: "flex",
        height: "100%",
      }}
    >
      {_currentFormData ? (
        <div
          className="p-fluid"
          style={{ width: "100%", height: "100%", marginTop: -20 }}
        >
          <h3>Algemene Informatie</h3>
          <div className="p-grid">
            <div className="p-col-12 p-md-6">
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="uitvaart_nummer">Uitvaart Nummer:</label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputText
                    disabled
                    tooltip="Het Uitvaart nummer wordt automatisch gegenereerd"
                    id="uitvaart_nummer"
                    onChange={_globalFieldChangeHandler}
                    value={_currentFormData?.uitvaart_nummer as any}
                    onFocus={() => handleFocus("uitvaart_nummer")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  <i className="pi pi-exclamation-circle" />
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="ondernemer_bedrijfsnaam">Ondernemer:</label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputText
                    autoFocus
                    disabled={isDisabled}
                    id="ondernemer_bedrijfsnaam"
                    onChange={_globalFieldChangeHandler}
                    value={_currentFormData?.ondernemer_bedrijfsnaam as any}
                    onFocus={() => handleFocus("ondernemer_bedrijfsnaam")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("ondernemer_bedrijfsnaam") ||
                    _currentFormData?.ondernemer_bedrijfsnaam) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "ondernemer_bedrijfsnaam",
                          _currentFormData?.ondernemer_bedrijfsnaam
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="kvk_nummer">KvK nummer:</label>
                </div>
                <div style={{ width: "40%" }} id="kvkHolder">
                  <InputText
                    disabled={isDisabled}
                    id="kvk_nummer"
                    onChange={_globalFieldChangeHandler}
                    value={_currentFormData?.kvk_nummer as any}
                    onBlur={(e: any) => {
                      let check = props.formControllerService.checkKvKNumber(
                        e.currentTarget.value
                      );
                      let inp = document.getElementById("kvkHolder");
                      if (!check && inp) {
                        inp.style.border = "2px solid red";
                      } else if (check && inp) {
                        inp.style.border = "none";
                      }
                    }}
                    onFocus={() => handleFocus("kvk_nummer")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("kvk_nummer") ||
                    _currentFormData?.kvk_nummer) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "kvk_nummer",
                          _currentFormData?.kvk_nummer
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="ondernemer_email">Emailadres:</label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputText
                    disabled={isDisabled}
                    id="ondernemer_email"
                    onChange={_globalFieldChangeHandler}
                    value={_currentFormData?.ondernemer_email as any}
                    onFocus={() => handleFocus("ondernemer_email")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("ondernemer_email") ||
                    _currentFormData?.ondernemer_email) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "ondernemer_email",
                          _currentFormData?.ondernemer_email
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="uitvaartleider_naam">
                    Uitvaartleider / leider:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputText
                    disabled={isDisabled}
                    id="uitvaartleider_naam"
                    onChange={_globalFieldChangeHandler}
                    value={_currentFormData?.uitvaartleider_naam as any}
                    onFocus={() => handleFocus("uitvaartleider_naam")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("uitvaartleider_naam") ||
                    _currentFormData?.uitvaartleider_naam) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "uitvaartleider_naam",
                          _currentFormData?.uitvaartleider_naam
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="uitvaartleider_telefoon">
                    Telefoon uitvaartleider / leider:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputText
                    disabled={isDisabled}
                    id="uitvaartleider_telefoon"
                    onChange={_globalFieldChangeHandler}
                    value={_currentFormData?.uitvaartleider_telefoon as any}
                    onFocus={() => handleFocus("uitvaartleider_telefoon")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("uitvaartleider_telefoon") ||
                    _currentFormData?.uitvaartleider_telefoon) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "uitvaartleider_telefoon",
                          _currentFormData?.uitvaartleider_telefoon
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="ondernemer_registratienummer">
                    Registratienummer:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputText
                    disabled={isDisabled}
                    id="ondernemer_registratienummer"
                    onChange={_globalFieldChangeHandler}
                    value={
                      _currentFormData?.ondernemer_registratienummer as any
                    }
                    onFocus={() => handleFocus("ondernemer_registratienummer")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("ondernemer_registratienummer") ||
                    _currentFormData?.ondernemer_registratienummer) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "ondernemer_registratienummer",
                          _currentFormData?.ondernemer_registratienummer
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="achternaam_overledene">
                    Achternaam overledene:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputText
                    disabled={isDisabled}
                    id="achternaam_overledene"
                    onChange={_globalFieldChangeHandler}
                    value={_currentFormData?.achternaam_overledene as any}
                    onFocus={() => handleFocus("achternaam_overledene")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("achternaam_overledene") ||
                    _currentFormData?.achternaam_overledene) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "achternaam_overledene",
                          _currentFormData?.achternaam_overledene
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="locatie">Locatie:</label>
                </div>
                <div style={{ width: "40%" }}>
                  <Dropdown
                    disabled={isDisabled}
                    id="locatie"
                    options={locationDropdown}
                    placeholder="Locatie"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("locatie")}
                    value={_currentFormData?.locatie as any}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("locatie") ||
                    _currentFormData?.locatie) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "locatie",
                          _currentFormData?.locatie
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="p-col-12 p-md-6">
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="datum_uitvaart">Datum uitvaart:</label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputMask
                    mask="99-99-9999"
                    disabled={isDisabled}
                    id="datum_uitvaart"
                    placeholder="Datum Uitvaart"
                    value={_currentFormData?.datum_uitvaart as any}
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("datum_uitvaart")}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {focusedFields.has("datum_uitvaart") && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "datum_uitvaart",
                          _currentFormData?.datum_uitvaart
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="aanvangstijd_uitvaart">
                    Aanvangstijd uitvaartdienst:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputMask
                    disabled={isDisabled || _currentFormData?.datum_uitvaart == ""}
                    id="aanvangstijd_uitvaart"
                    mask="99:99"
                    placeholder="Aanvangstijd uitvaartdienst"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("aanvangstijd_uitvaart")}
                    value={_currentFormData?.aanvangstijd_uitvaart as any}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {focusedFields.has("aanvangstijd_uitvaart") && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "aanvangstijd_uitvaart",
                          _currentFormData?.aanvangstijd_uitvaart
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }} className="padding">
                <div style={{ width: "45%" }}>
                  <label htmlFor="aanvangstijd_groeve">
                    Aanvangstijd aan de groeve:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <InputMask
                    disabled={isDisabled  || _currentFormData?.datum_uitvaart == ""}
                    id="aanvangstijd_groeve"
                    mask="99:99"
                    placeholder="Aanvangstijd aan de groeve"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("aanvangstijd_groeve")}
                    value={_currentFormData?.aanvangstijd_groeve as any}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {focusedFields.has("aanvangstijd_groeve") && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "aanvangstijd_groeve",
                          _currentFormData?.aanvangstijd_groeve
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div
                style={{ display: "none" }}
                className="padding"
                id="soort-control"
              >
                <div style={{ width: "45%" }}>
                  <label htmlFor="soort_begrafenis">Soort begrafenis:</label>
                </div>
                <div style={{ width: "40%" }}>
                  <Dropdown
                    disabled={isDisabled}
                    id="soort_begrafenis"
                    options={_funeralTypesDropdown}
                    placeholder="Soort begrafenis"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("soort_begrafenis")}
                    value={_currentFormData?.soort_begrafenis as any}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("soort_begrafenis") ||
                    _currentFormData?.soort_begrafenis) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "soort_begrafenis",
                          _currentFormData?.soort_begrafenis
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
              <div
                style={{ display: "none" }}
                id="new-exist"
                className="padding"
              >
                <div style={{ width: "45%" }}>
                  <label htmlFor="nieuw_of_bestaand_graf">
                    Nieuw of bestaand graf:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <Dropdown
                    id="nieuw_of_bestaand_graf"
                    disabled={isDisabled}
                    options={newExistingDropdown}
                    placeholder="Nieuw of bestaand graf"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("nieuw_of_bestaand_graf")}
                    value={_currentFormData?.nieuw_of_bestaand_graf as any}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("nieuw_of_bestaand_graf") ||
                    _currentFormData?.nieuw_of_bestaand_graf) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "nieuw_of_bestaand_graf",
                          _currentFormData?.nieuw_of_bestaand_graf
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>

              <div
                id="new-years"
                style={{ display: "none" }}
                className="padding"
              >
                <div style={{ width: "45%" }}>
                  <label htmlFor="nieuw_graf_aantal_jaren">Aantal jaren:</label>
                </div>
                <div style={{ width: "40%" }}>
                  <Dropdown
                    options={yearsDropdown}
                    disabled={isDisabled}
                    id="nieuw_graf_aantal_jaren"
                    placeholder="Aantal jaren"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("nieuw_graf_aantal_jaren")}
                    value={_currentFormData?.nieuw_graf_aantal_jaren as any}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("nieuw_graf_aantal_jaren") ||
                    _currentFormData?.nieuw_graf_aantal_jaren) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "nieuw_graf_aantal_jaren",
                          _currentFormData?.nieuw_graf_aantal_jaren
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>

              <div
                id="existing-extend"
                style={{ display: "none" }}
                className="padding"
              >
                <div style={{ width: "45%" }}>
                  <label htmlFor="bestaand_graf_verlenging">
                    Verlening bestaand graf:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <Dropdown
                    disabled={isDisabled}
                    id="bestaand_graf_verlenging"
                    options={jaaNeeDropdown}
                    placeholder="Verlening bestaand graf"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() => handleFocus("bestaand_graf_verlenging")}
                    value={_currentFormData?.bestaand_graf_verlenging as any}
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has("bestaand_graf_verlenging") ||
                    _currentFormData?.bestaand_graf_verlenging) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "bestaand_graf_verlenging",
                          _currentFormData?.bestaand_graf_verlenging
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>

              <div
                id="extend-years"
                style={{ display: "none" }}
                className="padding"
              >
                <div style={{ width: "45%" }}>
                  <label htmlFor="bestaand_graf_verlenging_aantal_jaren">
                    Aantal jaren verlenging:
                  </label>
                </div>
                <div style={{ width: "40%" }}>
                  <Dropdown
                    disabled={isDisabled}
                    id="bestaand_graf_verlenging_aantal_jaren"
                    options={yearsDropdown}
                    placeholder="Aantal jaren verlenging"
                    onChange={_globalFieldChangeHandler}
                    onFocus={() =>
                      handleFocus("bestaand_graf_verlenging_aantal_jaren")
                    }
                    value={
                      _currentFormData?.bestaand_graf_verlenging_aantal_jaren as any
                    }
                  />
                </div>
                <div style={{ width: "10%", margin: "auto" }}>
                  {(focusedFields.has(
                    "bestaand_graf_verlenging_aantal_jaren"
                  ) ||
                    _currentFormData?.bestaand_graf_verlenging_aantal_jaren) && (
                    <i
                      className={`pi ${
                        props.formControllerService.validateField(
                          "bestaand_graf_verlenging_aantal_jaren",
                          _currentFormData?.bestaand_graf_verlenging_aantal_jaren
                        )
                          ? "pi-check green"
                          : "pi-times red"
                      }`}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FormSectionOne;
